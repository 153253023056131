import CreatorRegistrationKeys from '../translationKeys/CreatorRegistrationPageKeys';

export const CreatorRegistrations = {
  [CreatorRegistrationKeys.complete_registration]: 'ลงทะเบียนให้เสร็จสมบูรณ์',
  [CreatorRegistrationKeys.success_registration]: 'การลงทะเบียนสำเร็จแล้ว!',
  [CreatorRegistrationKeys.evo_collected]: 'เก็บ EVO ได้แล้ว!',
  [CreatorRegistrationKeys.click_me]: 'คลิกฉัน!',
  [CreatorRegistrationKeys.touch_me]: 'สัมผัสฉัน',
  [CreatorRegistrationKeys._10_times]: '10 ครั้ง!',

  // Topbar
  [CreatorRegistrationKeys.Topbar.title]: 'ลงทะเบียนเป็นครีเอเตอร์',
  [CreatorRegistrationKeys.Topbar.time]: 'ประมาณ 1 นาที',
  [CreatorRegistrationKeys.Topbar.explain]:
    'โปรดเลือกอย่างน้อยหนึ่งหรือมากกว่าหนึ่งตัวเลือกสำหรับแต่ละส่วน',

  // Social Network
  [CreatorRegistrationKeys.Social_Network.tooltip]: `
    โปรดเชื่อมต่อบัญชีที่มีผู้ติดตาม <br>
    มากกว่า 500 คน <br>
    เพื่อเข้าร่วมเป็นครีเอเตอร์`,

  [CreatorRegistrationKeys.Social_Network.popup_error]:
    'โปรดอนุญาตป๊อปอัพในเบราว์เซอร์ของคุณก่อนเพื่อเชื่อมต่อแพลตฟอร์ม',
  [CreatorRegistrationKeys.Social_Network.server_error_tooltip]:
    'เนื่องจากการอัปเดต API ของ Facebook<br />การเชื่อมต่อจึงไม่สามารถใช้งานได้ชั่วคราว<br />กรุณาลองอีกครั้งหลังวันที่ 17 ธันวาคม',

  // Error
  [CreatorRegistrationKeys.Error.social_network]: 'โปรดเชื่อมต่อบัญชีโซเชียล',

  [CreatorRegistrationKeys.Error.handle_char_limit]: 'โปรดใส่ 3~30 ตัวอักษร',
  [CreatorRegistrationKeys.Error.handle_invalid]:
    'ตรวจพบสัญลักษณ์ไม่ถูกต้องหรือช่องว่าง',
  [CreatorRegistrationKeys.Error.handle_taken]: 'URL นี้ถูกใช้แล้ว',
  [CreatorRegistrationKeys.Error.handle_required]: 'โปรดใส่ URL',

  [CreatorRegistrationKeys.Error.category]: 'โปรดเลือกหมวดหมู่',
  [CreatorRegistrationKeys.Error.invalid_category]: 'หมวดหมู่ไม่ถูกต้อง',

  [CreatorRegistrationKeys.Error.target_country]: 'โปรดเลือกประเทศ',
  [CreatorRegistrationKeys.Error.invalid_country]: 'ประเทศไม่ถูกต้อง',

  [CreatorRegistrationKeys.Error.language]: 'โปรดเลือกภาษา',
  [CreatorRegistrationKeys.Error.invalid_language]: 'ภาษาไม่ถูกต้อง',

  // Modal
  // Already Creator
  [CreatorRegistrationKeys.Modal.already_creator.title]:
    'คุณลงทะเบียนเป็นครีเอเตอร์แล้ว',
  [CreatorRegistrationKeys.Modal.already_creator.description]: `
    คุณสามารถลงทะเบียนได้เพียงครั้งเดียวเท่านั้น <br>
    โปรดติดต่อฝ่ายสนับสนุนสำหรับข้อมูลเพิ่มเติม`,

  [CreatorRegistrationKeys.Modal.already_company.title]:
    'คุณได้ลงทะเบียนเป็นแบรนด์บริษัทแล้ว',
  [CreatorRegistrationKeys.Modal.already_company.description]:
    'คุณสามารถยืนยันได้ในฐานะครีเอเตอร์หรือแบรนด์เท่านั้น หากคุณต้องการลงทะเบียนเป็นครีเอเตอร์โปรดใช้บัญชีใหม่',

  // Registration Fail
  [CreatorRegistrationKeys.Modal.Registration_Fail.title]:
    'การลงทะเบียนล้มเหลว',

  [CreatorRegistrationKeys.Modal.Registration_Fail.Description.minimum_subs_l1]:
    'บัญชีต่อไปนี้ไม่มีผู้ติดตามอย่างน้อย 500 คน',
  [CreatorRegistrationKeys.Modal.Registration_Fail.Description.minimum_subs_l2]:
    'บัญชีของคุณควรมีผู้ติดตามอย่างน้อย 500 คนเพื่อดำเนินการต่อ',

  [CreatorRegistrationKeys.Modal.Registration_Fail.Description
    .already_verified_l1]:
    'บัญชีนี้ได้รับการยืนยันและถูกใช้งานอยู่แล้วโดยครีเอเตอร์คนอื่น',
  [CreatorRegistrationKeys.Modal.Registration_Fail.Description
    .already_verified_l2]: 'โปรดยกเลิกการเชื่อมต่อบัญชีและลองอีกครั้ง',

  // Verification Fail
  [CreatorRegistrationKeys.Modal.Verification_Fail.title]: 'การยืนยันล้มเหลว',
  [CreatorRegistrationKeys.Modal.Verification_Fail.scope_acceptance_required]:
    'จำเป็นต้องยอมรับสิทธิ์การเข้าถึง',

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.update_insta]: `
    โปรดอัปเดตบัญชี Instagram เป็นบัญชีครีเอเตอร์หรือธุรกิจแล้วลองอีกครั้ง <br>
    <u>คลิกที่นี่</u> เพื่อดูข้อมูลเพิ่มเติม.`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description
    .need_public_posts]: `
    บัญชีต้องมีโพสต์สาธารณะอย่างน้อย 10 โพสต์ เพื่อได้รับการยืนยันตัวตน โปรดลองอีกครั้งหลังจากอัปโหลดโพสต์เพิ่มเติม`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.no_connected]: `
    ไม่มีช่อง YouTube ที่เชื่อมโยงกับบัญชีของคุณ <br>
    โปรดเชื่อมต่อช่อง YouTube ของคุณแล้วลองอีกครั้ง`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.insta_to_use]:
    'คุณจำเป็นต้องยอมรับตัวเลือกการเข้าถึงของ Facebook(scopes) ทั้งหมด เพื่อใช้บริการของเรา ตัวเลือกเหล่านี้จำเป็นต่อบริการหลักของแพลตฟอร์มเรา และการปฏิเสธจะทำให้ฟังก์ชันการทำงานจำกัดและการเข้าถึงบริการของเราจะถูกจำกัด โปรดเชื่อมต่อบัญชีของคุณอีกครั้งโดยยอมรับตัวเลือกทั้งหมด',

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description
    .to_use_our_service]: `
    โปรดยอมรับสิทธิ์การเข้าถึงพื้นฐานที่จำเป็นสำหรับการสร้างโปรไฟล์ของคุณ<br/>สิทธิ์เหล่านี้ให้เฉพาะข้อมูลที่จำเป็นสำหรับครีเอเตอร์ในการใช้แพลตฟอร์มได้อย่างง่ายดายและปลอดภัย<br/>MegaEvolution เป็นแพลตฟอร์มระดับโลกที่ได้รับการยืนยันโดย Google (YouTube),<br/>Instagram และ TikTok`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.insta_different]:
    'คุณได้เลือกบัญชี Instagram ที่แตกต่างจากบัญชีที่เชื่อมต่ออยู่ในปัจจุบัน โปรดลองอีกครั้งโดยการเลือกบัญชีเดียวกัน หากคุณต้องการเชื่อมต่อบัญชีใหม่ โปรดยกเลิกการเชื่อมต่อบัญชีปัจจุบันและลองเชื่อมต่ออีกครั้ง',

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description
    .verify_social_media]: `
    คุณสามารถยืนยันบัญชีโซเชียลมีเดียได้ไม่เกิน 10 ครั้งต่อวัน <br>
    กรุณาลองใหม่อีกครั้งในภายหลัง`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description
    .already_verified]: `
    บัญชีนี้ถูกใช้งานอยู่โดยครีเอเตอร์คนอื่นแล้ว <br>
    โปรดลองอีกครั้งด้วยบัญชีอื่น`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.minimum_subs]:
    'บัญชีต้องมีผู้ติดตามอย่างน้อย 500 คนจึงจะได้รับการยืนยัน.',

  // Instagram Notice
  [CreatorRegistrationKeys.Modal.Instagram_Notice.update_connection]:
    'อัปเดตการเชื่อมต่อ',
  [CreatorRegistrationKeys.Modal.Instagram_Notice.upgrade_connection]:
    'อัปเกรดการเชื่อมต่อ',

  // Instagram Facebook
  [CreatorRegistrationKeys.Modal.Instagram_Facebook.title]:
    'อัปเดตการเชื่อมต่อกับเพจ Facebook',
  [CreatorRegistrationKeys.Modal.Instagram_Facebook.point1]: `
    เพิ่มเพจ Facebook เพื่ออัปเดตการเชื่อมต่อ Instagram ของคุณ <br>
    การอัปเดตนี้ <b>จำเป็นต้องยอมรับคำสั่งซื้อที่เกี่ยวข้องกับ Instagram</b> บนแพลตฟอร์ม`,
  [CreatorRegistrationKeys.Modal.Instagram_Facebook.point2]: `
    บัญชี Instagram<b>ครีเอเตอร์หรือธุรกิจนี้</b>จำเป็นต้องมีการอัปเดต`,
  [CreatorRegistrationKeys.Modal.Instagram_Facebook.point3]: `
    <b><u>คลิกที่นี่</u></b> เพื่อดูข้อมูลเพิ่มเติม`,
  [CreatorRegistrationKeys.Modal.loading_timeout.title]:
    'กรุณารีเฟรชและลองอีกครั้ง - ความท้าทายด้านความปลอดภัย',
  [CreatorRegistrationKeys.Modal.loading_timeout.description]:
    'กรุณาปิดแท็บเว็บไซต์ MegaEvolution อื่นๆ และลองใหม่อีกครั้ง เนื่องจากนโยบายความปลอดภัยของ Google, YouTube และ Instagram การลงทะเบียนบัญชีจะไม่สำเร็จหากคุณใช้เว็บไซต์ MegaEvolution ในแท็บอื่น',
};
