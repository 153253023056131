import Popup from 'components/Basics/Popup';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';
import CreatorRegistrationKeys from 'translations/translationKeys/CreatorRegistrationPageKeys';
import { useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import ProfileKeys from 'translations/translationKeys/ProfilePageKeys';
import Platform from 'constants/platform';

const Modals = ({ error, onClose, onPrimaryButtonClick }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [errorType, setErrorType] = useState();

  const { isMobile } = useResponsiveScreen();

  // set the insta modal openenig to true, for specific error codes
  // also for opening update connection modal

  useEffect(() => {
    if (error.code === 416) {
      setErrorType('inUseByAnotherUser');
    }
    if (error.code === 417) {
      setErrorType('notEnoughFollowers');
    }
    if (error.code === 418) {
      setErrorType('invalidScope');
    }
    if (error.code === 429) {
      setErrorType('rateLimitExceeded');
    }
    if (error.code === 432) {
      setErrorType('noYoutubeAccount');
    }
    if (error.code === 433) {
      setErrorType('updateInstaAcc');
    }
    if (error.code === 400) {
      setErrorType('notSameAccount');
    }
  }, [error.code]);

  return (
    <>
      <Popup
        title={t(CreatorRegistrationKeys.Modal.Verification_Fail.title)}
        description={
          <Trans
            components={{
              u: (
                <u
                  className="cursor-pointer"
                  onClick={() => navigate('/help-center')}
                ></u>
              ),
            }}
          >
            {t(
              CreatorRegistrationKeys.Modal.Verification_Fail.Description
                .update_insta
            )}
          </Trans>
        }
        show={errorType === 'updateInstaAcc'}
        onClose={() => {
          setErrorType(false);
          onClose();
        }}
        buttonText={t(GeneralKeys.return)}
        containerRadius={isMobile && '0.625rem'}
        dividerBelowTitle={isMobile}
      />
      <Popup
        title={t(CreatorRegistrationKeys.Modal.Verification_Fail.title)}
        description={
          <>
            {error.platform === Platform.Instagram ? (
              <Trans>
                {t(
                  CreatorRegistrationKeys.Modal.Verification_Fail.Description
                    .need_public_posts
                )}
              </Trans>
            ) : (
              t(
                CreatorRegistrationKeys.Modal.Verification_Fail.Description
                  .minimum_subs
              )
            )}
          </>
        }
        show={errorType === 'notEnoughFollowers'}
        onClose={() => {
          setErrorType(false);
          onClose();
        }}
        buttonText={t(GeneralKeys.return)}
        containerRadius={isMobile && '0.625rem'}
        dividerBelowTitle={isMobile}
      />
      <Popup
        title={t(CreatorRegistrationKeys.Modal.Verification_Fail.title)}
        description={
          <Trans>
            {t(
              CreatorRegistrationKeys.Modal.Verification_Fail.Description
                .already_verified
            )}
          </Trans>
        }
        show={errorType === 'inUseByAnotherUser'}
        onClose={() => {
          setErrorType(false);
          onClose();
        }}
        buttonText={t(GeneralKeys.return)}
        containerRadius={isMobile && '0.625rem'}
        dividerBelowTitle={isMobile}
      />
      <Popup
        title={t(CreatorRegistrationKeys.Modal.Verification_Fail.title)}
        description={
          <Trans>
            {t(
              CreatorRegistrationKeys.Modal.Verification_Fail.Description
                .verify_social_media
            )}
          </Trans>
        }
        show={errorType === 'rateLimitExceeded'}
        onClose={() => {
          console.log('onClose 429');
          setErrorType(false);
          onClose();
        }}
        buttonText={t(GeneralKeys.return)}
        containerRadius={isMobile && '0.625rem'}
        dividerBelowTitle={isMobile}
      />
      <Popup
        title={t(
          CreatorRegistrationKeys.Modal.Verification_Fail
            .scope_acceptance_required
        )}
        description={
          <>
            {error.platform === Platform.Instagram ? (
              <>
                {t(
                  CreatorRegistrationKeys.Modal.Verification_Fail.Description
                    .insta_to_use
                )}
              </>
            ) : (
              t(
                CreatorRegistrationKeys.Modal.Verification_Fail.Description
                  .to_use_our_service
              )
            )}
          </>
        }
        show={errorType === 'invalidScope'}
        onClose={() => {
          setErrorType(false);
          onClose();
        }}
        buttonText={t(GeneralKeys.return)}
        containerRadius={isMobile && '0.625rem'}
        dividerBelowTitle={isMobile}
      />
      <Popup
        title={t(CreatorRegistrationKeys.Modal.Verification_Fail.title)}
        description={
          <>
            {error.platform === Platform.Instagram ? (
              <>
                {t(
                  CreatorRegistrationKeys.Modal.Verification_Fail.Description
                    .insta_different
                )}
              </>
            ) : (
              <Trans>
                {t(
                  CreatorRegistrationKeys.Modal.Verification_Fail.Description
                    .no_connected
                )}
              </Trans>
            )}
          </>
        }
        show={errorType === 'noYoutubeAccount'}
        onClose={() => {
          setErrorType(false);
          onClose();
        }}
        buttonText={t(GeneralKeys.return)}
        containerRadius={isMobile && '0.625rem'}
        dividerBelowTitle={isMobile}
      />
      <Popup
        show={errorType === 'notSameAccount'}
        onClose={() => {
          setErrorType(false);
          onClose();
        }}
        title={t(ProfileKeys.Modal.Reconnection.Reconnect_Btn_Modal.title)}
        description={t(
          ProfileKeys.Modal.Reconnection.Reconnect_Btn_Modal.description
        )}
        buttonText={t(GeneralKeys.unlink)}
        onButtonClick={() => onPrimaryButtonClick(error.platform)}
        secondaryButtonText={t(GeneralKeys.return)}
      />
    </>
  );
};

export default Modals;
