import PricingKeys from '../translationKeys/PricingPageKeys';

export const PricingTranslations = {
  [PricingKeys.preparing]: '(ระหว่างจัดเตรียม)',
  [PricingKeys.order_now]: 'สั่งซื้อตอนนี้',
  [PricingKeys.get_free]: 'รับพรีเมียมพาสฟรี',
  [PricingKeys.get_for_free]: 'รับฟรี',
  [PricingKeys.current_plan]: 'แผนที่กำลังใช้งาน',
  [PricingKeys.company_verified]: 'บริษัทได้รับการรับรองแล้ว',

  // Banner
  [PricingKeys.Banner.title]: 'ทำการตลาดของคุณให้เป็นเรื่องง่ายและชาญฉลาด',
  [PricingKeys.Banner.description]:
    'บริการของเราจะช่วยให้คุณบรรลุเป้าหมายทางการตลาดได้ดีมากยิ่งขึ้น',

  [PricingKeys.Verified.Error.already_verified]: `
    บัญชีของคุณได้รับการยืนยันเเล้วว่าเป็นแบรนด์หรือบริษัท`,
  [PricingKeys.Verified.Error.already_using]: `
    บัญชีของคุณกำลังใช้แผนนี้อยู่แล้ว <br>
    <span>คลิกที่นี่</span> เพื่อดูการสมัครของคุณ`,
  [PricingKeys.Verified.Error.under_review]: `
    บัญชีของคุณอยู่ในขั้นตอนการตรวจสอบ <br>
    โปรดลองอีกครั้งทีหลัง`,
  [PricingKeys.Verified.Error
    .recently_rejected]: `คำขอของคุณได้รับการตรวจสอบเมื่อเร็ว ๆ นี้<br>โปรดลองอีกครั้งในภายหลัง`,

  // Left Col
  // General
  [PricingKeys.Left_Col.General.verification_req]: 'ต้องการการยืนยัน',
  [PricingKeys.Left_Col.General.smart_search]: 'ระบบค้นหาอัจฉริยะ',

  // Analytics & Data
  [PricingKeys.Left_Col.Analytics_Data.title]: 'วิเคราะห์และข้อมูล',

  [PricingKeys.Left_Col.Analytics_Data.Creator_Basic_Analytics.title]: `
    การวิเคราะห์พื้นฐานครีเอเตอร์`,
  [PricingKeys.Left_Col.Analytics_Data.Creator_Basic_Analytics.tooltip]: `
    การวิเคราะห์ช่องและเนื้อหา <br>
    ของบัญชีโซเชียลมีเดียของครีเอเตอร์`,

  [PricingKeys.Left_Col.Analytics_Data.audience_analytics]: 'วิเคราะห์ผู้ชม',

  [PricingKeys.Left_Col.Analytics_Data.Price_Unlock.title]: 'ปลดล็อกราคา',
  [PricingKeys.Left_Col.Analytics_Data.Price_Unlock.tooltip]: `
    คุณสามารถปลดล็อกราคาบริการของครีเอเตอร์ได้ <br>
    โดยการใช้โควต้ารายเดือน <br>
    ราคาจะถูกล็อกอีกครั้งหลังผ่านไป 72 ชั่วโมงจากการปลดล็อก`,

  [PricingKeys.Left_Col.Analytics_Data.order_analytics]: 'วิเคราะห์คำสั่งซื้อ',

  [PricingKeys.Left_Col.Analytics_Data.creator_reviews]: 'รีวิวครีเอเตอร์',

  // Orders
  [PricingKeys.Left_Col.Orders.title]: `คำสั่งซื้อและครีเอเตอร์`,
  [PricingKeys.Left_Col.Orders.order_packages]: 'สั่งซื้อแพ็กเกจครีเอเตอร์',

  [PricingKeys.Left_Col.Orders.message_creators]:
    'ส่งข้อความให้ครีเอเตอร์ใหม่ก่อนที่จะเริ่มสั่งซื้อ',

  [PricingKeys.Left_Col.Orders.Order_Discount.title]: 'ส่วนลดคำสั่งซื้อ',
  [PricingKeys.Left_Col.Orders.Order_Discount.tooltip_1]: `
    ส่วนลดจะถูกนำไปใช้กับราคารวมของแต่ละคำสั่งซื้อกับครีเอเตอร์ <br>
    * ไม่สามารถใช้กับสินค้าเสริม,การจัดส่งและค่าใช้จ่ายในการสมัครสมาชิกรายเดือน`,
  [PricingKeys.Left_Col.Orders.Order_Discount.tooltip_2]: `
    ส่วนลดจะถูกนำไปใช้กับราคารวมของแต่ละคำสั่งซื้อกับครีเอเตอร์ <br>
    ไม่สามารถใช้กับสินค้าเสริม,การสมัครสมาชิกแพลตฟอร์ม`,
  [PricingKeys.Left_Col.Orders.Order_Discount.content]: `
    <Tooltip><span>{{number}}</span></Tooltip>  ราคาสั่งซื้อ`,

  [PricingKeys.Left_Col.Orders.Track_Delivery.title]: `
    ติดตามการจัดส่งระหว่างประเทศ`,
  [PricingKeys.Left_Col.Orders.Track_Delivery.tooltip]: `
    เราช่วยติดตามการจัดส่งคำสั่งซื้อระหว่างประเทศ <br>
    การติดตามการจัดส่งภายในประเทศมีให้บริการสำหรับบางประเทศเท่านั้น <br><br>
    * หมายเหตุ: การจัดส่งเนื้อหาอาจไม่เป็นไปตามเนื้อหาต้นฉบับ`,

  [PricingKeys.Left_Col.Orders.auto_translation]: 'ระบบแปลภาษาอัตโนมัติ',

  [PricingKeys.Summary.basic]: `
    <li>ฟรีแต่มีประสิทธิภาพ</li>
    <li>ทุกสิ่งที่นึกได้ ทุกสิ่งที่คิดออก</li>`,
  [PricingKeys.Summary.verified]: `
    <li>ได้รับการยืนยันในเพียง 1-3 นาที</li>
    <li>เหมาะสำหรับแบรนด์และธุรกิจระดับโลกอย่างดี</li>`,
  [PricingKeys.Summary.premium]: `
    <li>ความจุขนาดใหญ่</li>
    <li>ต้องการการยืนยัน</li>
    <li>เหมาะสำหรับบริษัทระดับโลกที่มุ่งเน้นขยายธุรกิจ</li>`,

  // Creator
  [PricingKeys.Creator.title]: 'ไม่มีค่าธรรมเนียมการบริการสำหรับครีเอเตอร์',

  [PricingKeys.Creator.h1]: `
    ที่ MegaEvolution เรามุ่งมั่นที่จะเป็นแพลตฟอร์มที่เป็นมิตรกับครีเอเตอร์มากที่สุด! <br>
    นั่นคือเหตุผลที่เรา ไม่คิดค่าธรรมเนียมการบริการเพิ่มเติมจากครีเอเตอร์ — เพื่อให้คุณสามารถมุ่งเน้นไปที่สิ่งที่คุณทำได้ดีที่สุด นั่นคือ การสร้างสรรค์เนื้อหา`,
  [PricingKeys.Creator.h2]:
    'เราให้การร่วมมือกับแบรนด์ระดับโลกในหลากหลายสาขา เช่น แฟชั่น เทคโนโลยี ความงาม อาหาร ศิลปะ กีฬา AI ดนตรี และอื่นๆอีกมากมาย และเราจะให้การสนับสนุนเพื่อให้คุณสามารถเติบโตไปพร้อมกับครีเอเตอร์คนอื่นๆ',
  [PricingKeys.Creator.h3]: `
    * อาจมีค่าธรรมเนียมเพิ่มเติมสำหรับการทำธุรกรรมเล็กน้อย ที่จำเป็นในการถอนเงินของคุณ (เช่น Paypal)`,

  // Modal
  [PricingKeys.Modal.title]: 'การยืนยันแบรนด์และบริษัท',
  [PricingKeys.Modal.estimate]: 'ประมาณ 1-2 นาที',
  [PricingKeys.Modal.caution]:
    '* ข้อมูลที่เป็นเท็จหรือไม่ถูกต้องอาจทําให้การยืนยันสูญหายหรือการแบนบัญชีอย่างถาวร',
  [PricingKeys.Modal.toast]: 'บัญชีของคุณได้รับการยืนยันเรียบร้อยแล้ว!',

  [PricingKeys.Modal.Btn.proceed_final]: 'เข้าสู่ขั้นตอนสุดท้าย',
  [PricingKeys.Modal.Btn.verify_email]: 'ยืนยันอีเมล',
  [PricingKeys.Modal.Btn.no_company_email]: 'ฉันไม่มีอีเมลของบริษัท',
  [PricingKeys.Modal.Btn.verify_with_email]: 'ยืนยันด้วยอีเมล',
  [PricingKeys.Modal.Btn.complete_verification]: 'ทําการยืนยันให้เสร็จสมบูรณ์',
  [PricingKeys.Modal.Btn.another_email]: 'ใช้อีเมลอื่น',
  [PricingKeys.Modal.Btn.resend_code]: 'ส่งรหัสอีกครั้ง',

  [PricingKeys.Modal.Placeholder.firstname]: 'ป้อนชื่อของคุณ',
  [PricingKeys.Modal.Placeholder.lastname]: 'ป้อนนามสกุลของคุณ',
  [PricingKeys.Modal.Placeholder.companyname]: 'ป้อนชื่อบริษัท',
  [PricingKeys.Modal.Placeholder.companyUrl]:
    'ป้อน URL เว็บไซต์ของบริษัท(ตัวอย่าง: www.megaevolution.io)',
  [PricingKeys.Modal.Placeholder.companyUrl_sm]: 'ป้อน URL เว็บไซต์ของบริษัท',
  [PricingKeys.Modal.Placeholder.companyEmail]: 'ป้อนอีเมลบริษัทของคุณ',
  [PricingKeys.Modal.Placeholder.enter_code]: 'ใส่รหัสที่คุณได้รับ',

  [PricingKeys.Modal.CompanyType.title]: 'เลือกประเภทบริษัท',
  [PricingKeys.Modal.CompanyType.option_1]: 'แบรนด์และธุรกิจ',
  [PricingKeys.Modal.CompanyType.option_2]: 'ร้านค้าออนไลน์',
  [PricingKeys.Modal.CompanyType.option_3]: 'หน่วยงานการตลาด',
  [PricingKeys.Modal.CompanyType.option_4]: 'อื่นๆ',

  [PricingKeys.Modal.JobPosition.title]: 'เลือกตำแหน่งงาน',
  [PricingKeys.Modal.JobPosition.option_1]: 'ผู้ช่วย',
  [PricingKeys.Modal.JobPosition.option_2]: 'ผู้บริหารระดับสูง · รองประธาน',
  [PricingKeys.Modal.JobPosition.option_3]: 'ผู้อำนวยการ',
  [PricingKeys.Modal.JobPosition.option_4]: 'ผู้บริหาร',
  [PricingKeys.Modal.JobPosition.option_5]: 'หัวหน้าแผนก',
  [PricingKeys.Modal.JobPosition.option_6]: 'อาชีพอิสระ',
  [PricingKeys.Modal.JobPosition.option_7]: 'ผู้จัดการ · ผู้นำทีม',
  [PricingKeys.Modal.JobPosition.option_8]: 'นักศึกษาฝึกงาน',
  [PricingKeys.Modal.JobPosition.option_9]: 'สมาชิกทีม',

  // Final
  [PricingKeys.Modal.Final.explain]: `
    <li>โปรดอัปโหลดไฟล์ใดไฟล์หนึ่งต่อไปนี้เพื่อการยืนยัน: หนังสือรับรองการจดทะเบียนธุรกิจ, ใบกำกับภาษี, บัตรประจำตัวพนักงานหรือเอกสารอื่น ๆ ที่ยืนยันความมีอยู่ของบริษัทคุณได้</li>
    <li>ไฟล์ต้องอยู่ในรูปแบบ JPG, JPEG, PNG, หรือ PDF และมีความละเอียดอย่างน้อย 300dpi</li>`,
  [PricingKeys.Modal.Final.image_placeholder]:
    'อัปโหลดไฟล์ในรูปแบบ JPG, JPEG, PNG หรือ PDF ไม่เกิน 5 MB',
  [PricingKeys.Modal.Final.description_placeholder]:
    'โปรดบอกเราเกี่ยวกับ บริษัท สินค้า บริการหรือแบรนด์ที่คุณพยายามโปรโมทผ่านแพลตฟอร์ม',
  [PricingKeys.Modal.Final.spam_explain]:
    '* หากคุณไม่พบอีเมล โปรดตรวจสอบในจดหมายขยะ',

  // Popup
  [PricingKeys.Modal.Popup.title]: 'ฟอร์มของคุณถูกส่งสำเร็จแล้ว!',
  [PricingKeys.Modal.Popup.description]:
    'โดยทั่วไปการยืนยันจะใช้เวลา 1-3 วันทำการ คุณจะได้รับอีเมลแจ้งเตือนพร้อมผลลัพธ์หลังการตรวจสอบ',

  [PricingKeys.Modal.submission.toast]:
    'ส่งสำเร็จ! การตรวจสอบโดยทั่วไปจะใช้เวลา 1-3<br/> วันทำการ เราจะแจ้งให้คุณทราบผลเร็วๆ นี้',

  // Error
  [PricingKeys.Modal.Error.required_field]: 'ช่องที่ต้องระบุ',
  [PricingKeys.Modal.Error.select_option]: 'โปลดเลือก',
  [PricingKeys.Modal.Error.valid_url]: 'ป้อน URL ที่ถูกต้อง',
  [PricingKeys.Modal.Error.valid_email]: 'ป้อนอีเมลที่ถูกต้อง',
  [PricingKeys.Modal.Error.email_validation]:
    'ไม่สามารถใช้อีเมลส่วนตัวได้ (ตัวอย่าง gmail.com, yahoo.com และอื่น ๆ ใช้ไม่ได้)',
  [PricingKeys.Modal.Error.already_verified]: 'อีเมลนี้ถูกใช้ในการยืนยันแล้ว',
  [PricingKeys.Modal.Error.already_sent_code]:
    'รหัสถูกส่งไปยังอีเมลนี้แล้ว โปรดตรวจสอบกล่องจดหมายของคุณ',
  [PricingKeys.Modal.Error.invalid_code]: 'รหัสไม่ถูกต้อง',
  [PricingKeys.Modal.Error.invalid_file_format]:
    'รูปแบบไฟล์ไม่ถูกต้อง (โปรดใช้ {{fileFormats}})',
  [PricingKeys.Modal.Error.total_file_size]: 'ขนาดไฟล์ไม่เกิน {{maxSize}}',
  [PricingKeys.Modal.Error.send_max_files]:
    'คุณสามารถส่งไฟล์สูงสุด {{maxFiles}} ไฟล์ได้',
};
